// This file is generated automatically by `useTemplate.ts`. DO NOT EDIT IT.

import { createElement, forwardRef, Ref } from 'react';
import { IconBase, IconProps } from '../icon';

import '../style/css';

const element = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 16 16","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M6.43 9.92l6.23-6.22.92.92-7.15 7.14L1.97 7.3l.92-.92 3.54 3.54z","fillOpacity":0.9}}]};

const CheckIcon = forwardRef<SVGElement, IconProps>((props: IconProps, ref: Ref<SVGElement>) => createElement(
  IconBase,
  {
    ...props,
    id: 'check',
    ref,
    icon: element,
  },
));

CheckIcon.displayName = 'CheckIcon';

export default CheckIcon;
